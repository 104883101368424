module.exports = {
  'name': 'atanh',
  'category': 'Trigonometry',
  'syntax': [
    'atanh(x)'
  ],
  'description': 'Calculate the hyperbolic arctangent of a value, defined as `atanh(x) = ln((1 + x)/(1 - x)) / 2`.',
  'examples': [
    'atanh(0.5)'
  ],
  'seealso': [
    'acosh',
    'asinh'
  ]
};
