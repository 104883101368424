module.exports = {
  'name': 'asech',
  'category': 'Trigonometry',
  'syntax': [
    'asech(x)'
  ],
  'description': 'Calculate the inverse secant of a value.',
  'examples': [
    'asech(0.5)'
  ],
  'seealso': [
    'acsch',
    'acoth'
  ]
};
