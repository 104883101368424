module.exports = {
  'name': 'factorial',
  'category': 'Probability',
  'syntax': [
    'kldivergence(x, y)'
  ],
  'description': 'Compute the factorial of a value',
  'examples': [
    '5!',
    '5 * 4 * 3 * 2 * 1',
    '3!'
  ],
  'seealso': ['combinations', 'permutations', 'gamma']
};
