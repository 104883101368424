module.exports = [
  require('./compare'),
  require('./compareNatural'),
  require('./deepEqual'),
  require('./equal'),
  require('./larger'),
  require('./largerEq'),
  require('./smaller'),
  require('./smallerEq'),
  require('./unequal')
];
