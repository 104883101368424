module.exports = {
  'name': 'isNaN',
  'category': 'Utils',
  'syntax': [
    'isNaN(x)'
  ],
  'description': 'Test whether a value is NaN (not a number)',
  'examples': [
    'isNaN(2)',
    'isNaN(0 / 0)',
    'isNaN(NaN)',
    'isNaN(Infinity)'
  ],
  'seealso': ['isNegative', 'isNumeric', 'isPositive', 'isZero']
};
