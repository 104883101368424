module.exports = {
  'name': 'intersect',
  'category': 'Geometry',
  'syntax': [
    'intersect(expr1, expr2, expr3, expr4)',
    'intersect(expr1, expr2, expr3)'
  ],
  'description': 'Computes the intersection point of lines and/or planes.',
  'examples': [
    'intersect([0, 0], [10, 10], [10, 0], [0, 10])',
    'intersect([1, 0, 1],  [4, -2, 2], [1, 1, 1, 6])'
  ],
  'seealso': []
};
