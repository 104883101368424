module.exports = {
  'name': 'tau',
  'category': 'Constants',
  'syntax': [
    'tau'
  ],
  'description': 'Tau is the ratio constant of a circle\'s circumference to radius, equal to 2 * pi, approximately 6.2832.',
  'examples': [
    'tau',
    '2 * pi'
  ],
  'seealso': ['pi']
};
