module.exports = [
  require('./docs'),
  require('./function'),
  require('./node'),
  require('./transform'),

  require('./Help'),
  require('./parse'),
  require('./Parser')
];
