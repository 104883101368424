module.exports = {
  'name': 'rightLogShift',
  'category': 'Bitwise',
  'syntax': [
    'x >> y',
    'leftShift(x, y)'
  ],
  'description': 'Bitwise right logical shift of a value x by y number of bits.',
  'examples': [
    '8 >>> 1',
    '4 << 1',
    '-12 >>> 2'
  ],
  'seealso': [
    'bitAnd', 'bitNot', 'bitOr', 'bitXor', 'leftShift', 'rightArithShift'
  ]
};
