module.exports = {
  'name': 'acot',
  'category': 'Trigonometry',
  'syntax': [
    'acot(x)'
  ],
  'description': 'Calculate the inverse cotangent of a value.',
  'examples': [
    'acot(0.5)',
    'acot(cot(0.5))',
    'acot(2)'
  ],
  'seealso': [
    'cot',
    'atan'
  ]
};
